@import 'theme/Color';

a {
  color: $color-link;
  text-decoration: none;
  font-weight: 700;

  &:focus,
  &:hover {
    text-decoration: underline;
  }
}
