@import 'theme/Breakpoints';
@import 'theme/Color';

.table {
  border-spacing: 0;
  overflow-x: auto;
  text-align: left;
  width: 100%;
  td,
  th {
    padding: 1.2rem 1.5rem;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  td {
    font-size: 1.4rem;
    border-bottom: 0.1rem solid $color-border;
    font-variant-numeric: tabular-nums;
  }
  th,
  td {
    &:global(.numeric) {
      text-align: right;
    }
  }
  th {
    cursor: pointer;
    font-size: 1.6rem;
    font-weight: 700;
    border-bottom: 0.1rem solid $color-border-heavy;
  }
  @media (min-width: $width-mobile) {
    th {
      font-size: 1.8rem;
    }
    td {
      font-size: 1.6rem;
    }
    table {
      overflow-x: initial;
    }
  }
}

.arrows {
  margin-top: 0px;
  padding-left: 2rem;
  display: inline-block;
  width: 12px;
  height: 14px;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5.894 6 0 0 5.894h12Z' fill='%23000000'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 8.106 6 14l6-5.894H0Z' fill='%23000000'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center right;
}
.arrows.descending {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5.894 6 0 0 5.894h12Z' fill='%23DDDDDD'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 8.106 6 14l6-5.894H0Z' fill='%2334AC68'/%3E%3C/svg%3E");
}
.arrows.ascending {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5.894 6 0 0 5.894h12Z' fill='%2334AC68'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 8.106 6 14l6-5.894H0Z' fill='%23DDDDDD'/%3E%3C/svg%3E");
}

.ascending,
.descending {
  color: $color-link;
}

.scrollWrap {
  overflow-x: auto;
  width: 100%;
  white-space: nowrap;
}
