.container {
  padding: 8px 0;
  display: flex;
  & > svg {
    margin-right: 16px;
  }
  align-items: flex-start;
  cursor: pointer;
}

.container input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.label {
  display: flex;
  flex-direction: column;
  min-height: 26px;
  justify-content: center;
}
