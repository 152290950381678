@import 'theme/Color';

.DayPicker {
  display: block;
  font-size: 1rem;
  min-width: 38px * 7;
}

.DayPicker-wrapper {
  position: relative;
  flex-direction: row;
  user-select: none;
}

.DayPicker-NavButton {
  position: absolute;
  top: 1.5rem;
  display: block;
  margin-top: -15px;
  width: 14px;
  height: 30px;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: #8b9898;
  cursor: pointer;
}

.DayPicker-NavButton:hover {
  opacity: 0.8;
}

.DayPicker-NavButton--prev {
  left: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='7' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.754 14.874 7 13.364 2.414 7.991 7 2.432 5.754.922 0 7.898l5.754 6.976Z' fill='%23514E4E'/%3E%3C/svg%3E");
}

.DayPicker-NavButton--next {
  right: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='7' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.246.921 0 2.432l4.586 5.371L0 13.363l1.246 1.51L7 7.897 1.246.921Z' fill='%23514E4E'/%3E%3C/svg%3E");
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  text-align: center;
  font-size: 1.8rem;
  line-height: 3rem;
}

.DayPicker-Caption > div {
  font-weight: 500;
  font-size: 1.15em;
}

.DayPicker-Weekdays {
  margin-top: 1em;
  margin-bottom: 1px;
  padding: 12px 0;
  border-top: 1px solid $color-border;
  border-bottom: 1px solid $color-border;
}

.DayPicker-WeekdaysRow {
  display: flex;
}

.DayPicker-Weekday {
  flex: 1 0;
  color: $color-label;
  text-align: center;
  font-size: 1.4rem;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Week {
  display: flex;
  padding: 1px 0;
}

.DayPicker-Day {
  flex: 1 0;
  height: 38px;
  line-height: 38px;
  font-size: 14px;
  border: 1px solid transparent; // prevents layout shifts when making a selection
  border-radius: 19px;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}

.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.5em;
  min-width: 1em;
  border-right: 1px solid #eaecec;
  color: #8b9898;
  vertical-align: middle;
  text-align: right;
  font-size: 0.75em;
  cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: 0.5em;
}

.DayPicker-TodayButton {
  border: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: #4a90e2;
  font-size: 0.875em;
  cursor: pointer;
}

.DayPicker-Day--today {
  font-weight: 700;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  border-color: #34ac68;
  border-width: 1px;
  border-radius: 0;
  border-style: solid none solid none;
  background-color: #e9f7ef;
}

.DayPicker-Day--end:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  border-style: solid solid solid none;
  border-radius: 0 19px 19px 0;
}

.DayPicker-Day--start:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  border-style: solid none solid solid;
  border-radius: 19px 0 0 19px;
}

.DayPicker-Day--start.DayPicker-Day--end:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  border-style: solid;
  border-radius: 19px;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #51a0fa;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #f0f8ff;
}
