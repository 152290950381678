@import 'theme/Color';

.linkButton {
  display: block;
  padding: 0 2.4rem;
  border-radius: 8px;
  white-space: nowrap;
  line-height: 3.6rem;
  &:hover {
    background-color: $color-input-secondary-hover;
  }
}
