@import 'theme/Breakpoints';

// .container is main centered wrapper with a max width of 112.0rem (1120px)
.container {
  margin: 0 auto;
  max-width: 1400px;
  padding: 0 16px;
  position: relative;
  width: 100%;
}
// Using flexbox for the grid, inspired by Philip Walton:
// http://philipwalton.github.io/solved-by-flexbox/demos/grids/
// By default each .column within a .row will evenly take up
// available width, and the height of each .column with take
// up the height of the tallest .column in the same .row
.row {
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;

  &.row-no-padding {
    padding: 0;

    & > .column {
      padding: 0;
    }
  }
  &.row-wrap {
    flex-wrap: wrap;
  }
  // Vertically Align Columns
  // .row-* vertically aligns every .col in the .row
  &.row-top {
    align-items: flex-start;
  }
  &.row-bottom {
    align-items: flex-end;
  }
  &.row-center {
    align-items: center;
  }
  &.row-stretch {
    align-items: stretch;
  }
  &.row-baseline {
    align-items: baseline;
  }
  .column {
    display: block;
    // IE 11 required specifying the flex-basis otherwise it breaks mobile
    flex: 1 1 auto;
    margin-left: 0;
    max-width: 100%;
    width: 100%;

    // Column Offsets
    &.column-offset-10 {
      margin-left: 10%;
    }
    &.column-offset-20 {
      margin-left: 20%;
    }
    &.column-offset-25 {
      margin-left: 25%;
    }
    &.column-offset-33,
    &.column-offset-34 {
      margin-left: 33.3333%;
    }
    &.column-offset-40 {
      margin-left: 40%;
    }
    &.column-offset-50 {
      margin-left: 50%;
    }
    &.column-offset-60 {
      margin-left: 60%;
    }
    &.column-offset-66,
    &.column-offset-67 {
      margin-left: 66.6666%;
    }
    &.column-offset-75 {
      margin-left: 75%;
    }
    &.column-offset-80 {
      margin-left: 80%;
    }
    &.column-offset-90 {
      margin-left: 90%;
    }
    // Explicit Column Percent Sizes
    // By default each grid column will evenly distribute
    // across the grid. However, you can specify individual
    // columns to take up a certain size of the available area
    &.column-10 {
      flex: 0 0 10%;
      max-width: 10%;
    }
    &.column-20 {
      flex: 0 0 20%;
      max-width: 20%;
    }
    &.column-25 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    &.column-33,
    &.column-34 {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
    }
    &.column-40 {
      flex: 0 0 40%;
      max-width: 40%;
    }
    &.column-50 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    &.column-60 {
      flex: 0 0 60%;
      max-width: 60%;
    }
    &.column-66,
    &.column-67 {
      flex: 0 0 66.6666%;
      max-width: 66.6666%;
    }
    &.column-75 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    &.column-80 {
      flex: 0 0 80%;
      max-width: 80%;
    }
    &.column-90 {
      flex: 0 0 90%;
      max-width: 90%;
    }
    // .column-* vertically aligns an individual .column
    .column-top {
      align-self: flex-start;
    }
    .column-bottom {
      align-self: flex-end;
    }
    .column-center {
      align-self: center;
    }
    // Larger than mobile screen
  }
}
@media (min-width: $width-mobile) {
  .row {
    flex-direction: row;
    margin-left: -1rem;
    width: calc(100% + 2rem);

    .column {
      margin-bottom: inherit;
      padding: 0 1rem;
    }
  }
} ;
