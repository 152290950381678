@import 'theme/Color';

.container {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  position: relative;
  border-radius: 4px;
  border: 1px solid $color-border;
  height: 56px;
  &:focus-within {
    border-color: $color-input-primary-focus;
  }
}

.input {
  caret-color: $color-input-primary-focus;
  height: 36px;
  line-height: 24px;
  padding: 0 12px;
  background: transparent;
  position: absolute;
  bottom: 0;
}

.label {
  color: $color-label;
  font-size: 18px;
  padding: 0 18px;
  position: absolute;
  transform-origin: top left;
  transform: translate(0, 15px) scale(1);
  transition: all 0.2s ease-out;
  :focus-within + &,
  :focus + &,
  :global(.active) & {
    font-size: 14px;
    transform: translate(0, 8px) scale(0.75);
  }
}
