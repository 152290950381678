@import 'theme/Color';

.header {
  border-bottom: 1px solid $color-border;
  position: sticky;
  background: rgba(255, 255, 255, 0.98);
  z-index: 10;
  top: 0;
}

.container {
  font-weight: 500;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  height: 1.8rem;
  display: block;
}
