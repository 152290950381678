@import 'theme/Breakpoints';
@import 'theme/Color';

.dropdown {
  position: absolute;
  top: 48px;
  right: 0;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(6, 61, 28, 0.12);
  border-radius: 8px;
  z-index: 1000;
  padding: 16px;
  min-width: 328px;
}

.name {
  margin-left: 12px;
  display: none;
  @media (min-width: $width-mobile) {
    display: block;
  }
}

.menu {
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover .avatar,
  &.active .avatar {
    box-shadow: 0px 0px 0px 4px #e9f6ef;
  }
}
