@import 'theme/Color';

.footer {
  height: 72px;
  display: flex;
  align-items: center;
  border-top: 1px solid $color-border;
}

.logo {
  height: 1.8rem;
}
