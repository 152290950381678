@import 'theme/Color';

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.symbol {
  color: $color-label;
}

.pad {
  color: $color-label;
}

.active .pad {
  color: $color-placeholder;
}

.placeholder {
  position: absolute;
  top: 26px;
  right: 16px;
  text-align: right;
  pointer-events: none;
  display: none;
  label:focus-within &,
  :global(label.active) & {
    display: block;
  }
}
