// @import "theme/Color";

// // input[type="color"],
// // input[type="date"],
// // input[type="datetime"],
// // input[type="datetime-local"],
// // input[type="email"],
// // input[type="month"],
// // input[type="number"],
// // input[type="password"],
// // input[type="search"],
// // input[type="tel"],
// // input[type="text"],
// // input[type="url"],
// // input[type="week"],
// // input:not([type]),
// // textarea,
// select {
//   // -webkit-appearance: none;
//   // sass-lint:disable-line no-vendor-prefixes
//   // background-color: transparent;
//   // border: 0.1rem solid $color-border;
//   // border-radius: 0.4rem;
//   // box-shadow: none;
//   // box-sizing: inherit;
//   // Forced to replace inherit values of the normalize.css
//   height: 5.6rem;
//   padding: 0.6rem 1rem 0.7rem;
//   // This vertically centers text on FF, ignored by Webkit
//   width: 100%;

//   &:focus {
//     border-color: $color-border;
//     outline: 0;
//   }
// }

// textarea {
//   min-height: 6.5rem;
// }
// label,
// legend {
//   display: block;
//   font-size: 1.6rem;
//   font-weight: 700;
//   margin-bottom: 0.5rem;
// }
// fieldset {
//   border-width: 0;
//   padding: 0;
// }
// input[type="checkbox"],
// input[type="radio"] {
//   display: inline;
// }
// .label-inline {
//   display: inline-block;
//   font-weight: normal;
//   margin-left: 0.5rem;
// }

.hidden {
  width: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  height: 0 !important;
  margin: 0 !important;
  appearance: none !important;
  position: absolute !important;
}
