@import 'theme/Color';
@import 'theme/Breakpoints';

.filters {
  border-bottom: 1px solid $color-border;
  padding-bottom: 3.2rem;
  margin-bottom: 3.2rem;
}

.row {
  display: flex;
  margin: -6px -10px;
  flex-wrap: wrap;
}

.filter {
  flex: 0 1 328px;
  margin: 6px 10px;
  @media (min-width: $width-mobile) {
    font-size: 3.2rem;
  }
  select {
    display: block;
  }
}
