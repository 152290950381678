@import 'theme/Color';

.input {
  -webkit-appearance: none;
  box-shadow: none;
  box-sizing: inherit;
  padding: 6px 16px;
  font-size: 1.8rem;
  line-height: 32px;
  border: none;
  &:focus {
    outline: 0;
  }
}
