@import 'theme/Color';

.tab {
  border: 1px solid $color-border;
  border-radius: 24px;
  height: 48px;
  // margin: 0 8px;
  padding: 0 24px;
  cursor: pointer;
  &:not(:last-child) {
    margin-right: 16px;
  }
}
.selected {
  border: 1px solid $color-input-primary-focus;
  background-color: $color-selection;
  .tabLabel {
    color: $color-body;
  }
}
.tabBar {
  display: flex;
  height: 52px;
}
.tabLabel {
  line-height: 48px;
  font-size: 1.6rem;
  font-weight: 500;
  color: $color-label;
}
