@import 'theme/Color';

.legend {
  margin: 24px 0 32px;
  font-size: 1.4rem;
}

.legendItem {
  display: flex;
  align-items: center;
}
.legendItem + .legendItem {
  margin-top: 0.25rem;
}

.swatch {
  border-radius: 100%;
  width: 12px;
  height: 12px;
  margin-right: 12px;
  margin-top: -1px;
}

.legendLabel {
  font-weight: 500;
}
