@import 'theme/Color';

.item {
  font-weight: 500;
}

.modifier {
  color: $color-label;
  font-size: 1.4rem;
}

.maxLabel {
  margin-top: 8px;
  color: $color-label;
  font-size: 1.4rem;
}
