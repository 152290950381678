@import 'theme/Breakpoints';
@import 'theme/Color';

.button {
  cursor: pointer;
  padding: 11px 9px;
  svg {
    display: block;
  }
}

.active,
.button:hover {
  background-color: $color-input-tertiary-hover;
}

.dropdown {
  position: absolute;
  top: 48px;
  left: 0;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(6, 61, 28, 0.12);
  border-radius: 8px;
  z-index: 1000;
  padding: 4px 16px;
  min-width: 328px;
  & > div {
    padding: 8px 0;
  }
  & > div:not(:last-child) {
    border-bottom: 1px solid $color-border;
  }
}
