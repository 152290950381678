@import 'theme/Color';

.switch {
  display: flex;
  font-size: 1.4rem;
  font-weight: 500;
  align-items: center;
}

.option {
  cursor: pointer;
  line-height: 44px;
  padding: 0 16px;
  flex: 0 0 50%;
  color: $color-label;
  border-style: solid;
  border-color: $color-border;
}

.optionA {
  border-radius: 4px 0 0 4px;
  border-width: 1px 0 1px 1px;
}

.optionB {
  border-radius: 0 4px 4px 0;
  border-width: 1px 1px 1px 0;
}

.active {
  color: $color-input-primary-label;
  border-color: $color-input-primary-background;
  background: $color-input-primary-background;
}
