@import 'theme/Color';

.error {
  border: 1px solid $color-border;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .emoji {
    font-size: 3.2rem;
    margin-bottom: 1.6rem;
  }
  .title {
    font-size: 1.6rem;
    font-weight: 700;
  }
  .description {
    font-size: 1.4rem;
  }
}
