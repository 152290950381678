@import 'theme/Color';

.tooltip {
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(6, 61, 28, 0.12);
  border-radius: 8px;
  position: absolute;
  z-index: 1000;
}

.group {
  padding: 16px 24px;
}

.group + .group {
  border-top: 1px solid $color-border;
}

.header {
  color: $color-label;
  font-weight: 500;
  padding: 0 0 8px;
}

.body {
  display: flex;
}

.legendItem {
  display: flex;
  white-space: nowrap;
  &:not(:last-child) {
    margin-right: 24px;
  }
}

.swatch {
  border-radius: 100%;
  width: 16px;
  height: 16px;
  margin-right: 12px;
  margin-top: 3px;
}

.value {
  font-weight: 700;
  font-size: 2rem;
}
