.screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 144px);
  padding-top: 144px;
}

.madeIn {
  text-align: center;
  font-size: 1.6rem;
  font-weight: 500;
  margin: 64px 0 32px 0;
}

.emoji {
  font-size: 3.2rem;
  will-change: transform;
  &:hover {
    transition: transform 750ms ease-in-out;
    transform: rotate(720deg);
  }
}
