@import 'theme/Color';
@import 'theme/Breakpoints';

.table {
  width: 100%;
  border-collapse: collapse;
  tr td:nth-child(2) {
    padding-left: 5em;
    text-align: right;
  }
  tbody tr:last-child td,
  tfoot tr:nth-last-child(2) td {
    padding-bottom: 8px;
  }
  tfoot {
    tr:not(:last-child) {
      color: $color-label;
    }
    tr:first-child,
    tr:last-child {
      td {
        border-top: 1px solid #eee;
        padding-top: 8px;
      }
    }
    tr:last-child {
      font-weight: 500;
    }
  }
}

.debit {
  color: #ff0000;
}

.itemRow {
  font-weight: 500;
}
.modifierRow {
  color: $color-label;
}

.discountRow {
  color: $color-label;
}

.row {
  display: flex;
  margin: -6px -10px;
  flex-wrap: wrap;
}

.filter {
  flex: 0 1 328px;
  margin: 6px 10px;
  @media (min-width: $width-mobile) {
    font-size: 3.2rem;
  }
}

.discountText {
  color: #34ac68;
}
