@import 'theme/Color';

.viewMore {
  font-weight: 700;
  color: $color-link;
  padding: 1.2rem 0;
  display: inline-block;
  cursor: pointer;
  background: url("data:image/svg+xml,%3Csvg width='14' height='8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 1.424 12.484 0l-5.39 5.24L1.517 0 0 1.424 7 8l7-6.576Z' fill='%2334AC68'/%3E%3C/svg%3E")
    center right no-repeat;
  padding-right: 3rem;
  &:hover {
    opacity: 0.8;
  }
}
