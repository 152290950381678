@import 'theme/Color';

.select {
  font-family: 'Gotham';
  font-weight: 500;
  font-size: 1.6rem;
  -webkit-appearance: none;
  box-shadow: none;
  box-sizing: inherit;
  background: $color-input-secondary-background
    url("data:image/svg+xml,%3Csvg width='38' height='8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 1.746 12.484.5l-5.39 4.586L1.517.5 0 1.746 7 7.5l7-5.754Z' fill='%23514E4E'/%3E%3C/svg%3E")
    center right no-repeat;
  border-radius: 0.4rem;
  border: none;
  height: 5.6rem;
  line-height: 5.6rem;
  padding: 0 62px 0 24px;
  // This vertically centers text on FF, ignored by Webkit
  width: 100%;
  min-width: 120px;
  cursor: pointer;

  &:hover {
    background-color: $color-input-secondary-hover;
  }
  &:focus {
    outline: 0;
    // background-color: $color-input-primary-focus;
  }
  &[multiple] {
    background: none;
    height: auto;
  }
}

.dropdown {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(6, 61, 28, 0.12);
  border-radius: 8px;
  top: 64px;
  right: 0;
  z-index: 1000;
  max-height: 500px;
  padding: 0 24px;
}

.option {
  padding: 16px 0px;
  display: flex;
  font-size: 1.8rem;
  & > svg {
    margin-left: 5em;
    margin-right: 4px;
  }
  align-items: flex-start;
  cursor: pointer;
  & + & {
    padding-top: 16px;
    border-top: 1px solid $color-border;
  }
  &:hover {
    color: #444;
  }
}
