@import 'theme/Color';

.secondary {
  background-color: $color-input-secondary-background;
  color: $color-input-secondary-label;

  &:hover,
  &:global(.active) {
    background-color: $color-input-secondary-hover;
    text-decoration: none;
  }
  &[disabled] {
    &:focus,
    &:hover {
      background-color: $color-input-secondary-background-disabled;
    }
  }
}
