$color-body: #090404 !default; // AlmostBlack
$color-border: #dddddd !default; // Gray20
$color-placeholder: #dddddd !default; // Gray20
$color-border-heavy: #c5c5c5 !default; // Gray30
$color-label: #797979 !default; // Gray70
$color-selection: #e4fbed !default; // Mint
$color-link: #34ac68 !default;
$color-error: #ff0000 !default;

// green button
$color-input-primary-label: #ffffff !default;
$color-input-primary-background: #34ac68 !default;
$color-input-primary-background-disabled: #f2f2f1 !default;
$color-input-primary-hover: #34ac68 !default;
$color-input-primary-focus: #34ac68 !default;

// grey button
$color-input-secondary-label: $color-link !default;
$color-input-secondary-background: #f2f2f1 !default;
$color-input-secondary-background-disabled: #f2f2f1 !default;
$color-input-secondary-hover: #e9f7ef !default;
$color-input-secondary-focus: #ddeae2 !default;

// ?
$color-input-tertiary-label: #514e4e !default;
$color-input-tertiary-background: #f2f2f1 !default;
$color-input-tertiary-background-disabled: #f2f2f1 !default;
$color-input-tertiary-hover: #e9f7ef !default;
$color-input-tertiary-focus: #ddeae2 !default;
