@import 'theme/Color';

.dropdown {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(6, 61, 28, 0.12);
  border-radius: 8px;
  top: 64px;
  right: 0;
  z-index: 1000;
  max-height: 500px;
}

.option {
  padding: 16px 24px;
  display: flex;
  font-size: 1.8rem;
  & > svg {
    margin-right: 16px;
  }
  align-items: flex-start;
  cursor: pointer;
  & + & {
    padding-top: 16px;
    border-top: 1px solid $color-border;
  }
  &:hover {
    background-color: #f8f8f8;
  }
}
