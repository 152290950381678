@font-face {
  font-family: 'Gotham';
  font-weight: 400;
  src: url('./fonts/GothamSSm-Book.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 500;
  src: url('./fonts/GothamSSm-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 700;
  src: url('./fonts/GothamSSm-Bold.otf') format('opentype');
}
