.reaction {
  display: flex;
}
.reactionReasons {
  font-weight: 500;
}
.reactionNote {
  margin-top: 4px;
}

.reactionEmoji {
  font-size: 32px;
  margin-right: 1em;
}
