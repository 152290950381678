@import 'theme/Breakpoints';

.tiles {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}
.tile {
  margin: 10px;
  font-size: 3.2rem;
  flex: 1 0 34%;
  max-width: calc(50% - 20px);
  @media (min-width: $width-mobile) {
    flex: 0 1 212px;
  }
}
.label {
  font-size: 1.8rem;
  font-weight: 400;
}
.value {
  font-size: 2.4rem;
  font-weight: 700;
}
