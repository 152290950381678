@import 'theme/Color';

.button {
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 500;
  padding: 0 1.8rem;
  text-decoration: none;
  white-space: nowrap;
  display: flex;
  align-items: center;
  height: 5.6rem;
  line-height: 5.6rem;
  justify-content: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

  &:focus,
  &:hover {
    outline: 0;
  }
  &[disabled] {
    cursor: default;
  }
}

.primary {
  background-color: $color-input-primary-background;
  color: $color-input-primary-label;

  &:hover {
    background-color: $color-input-primary-hover;
  }
  &[disabled] {
    background-color: $color-input-primary-background-disabled;
  }
}
