.dropdown {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(6, 61, 28, 0.12);
  border-radius: 8px;
  top: 64px;
  right: 0;
  padding: 16px;
  z-index: 1000;
}
