@import 'theme/Breakpoints';
// The base font-size is set at 62.5% for having the convenience
// of sizing rems in a way that is similar to using px: 1.6rem = 16px

html {
  font-size: 62.5%;
}

body {
  color: $color-body;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
button,
select,
input {
  font-family: 'Gotham';
}

b,
strong {
  font-weight: bold;
}
p {
  margin-top: 0;
  max-width: 50em;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin-bottom: 2rem;
  margin-top: 0;
}
h1 {
  margin-top: 48px;
  font-size: 2.4rem;
  line-height: 1.125;
  @media (min-width: $width-mobile) {
    font-size: 3.2rem;
  }
}
h2 {
  font-size: 2.4rem;
  line-height: 1.125;
}
h3 {
  color: $color-label;
  font-size: 1.8rem;
  line-height: 1.125;
}
h4 {
  font-size: 2.2rem;
  line-height: 1.35;
}
h5 {
  font-size: 1.8rem;
  letter-spacing: -0.05rem;
  line-height: 1.5;
}
h6 {
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 1.4;
}
