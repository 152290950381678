@import 'theme/Color';

.avatar {
  overflow: hidden;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-input-secondary-background;
  font-size: 1.2rem;
}
