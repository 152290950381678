dd,
dt,
li {
  margin-bottom: 1rem;
}

fieldset {
  margin-bottom: 1.5rem;
}

blockquote,
dl,
figure,
ol,
p,
pre,
ul {
  margin-bottom: 2.5rem;
}
