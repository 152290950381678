@import 'theme/Breakpoints';
@import 'theme/Color';

.chartHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.6rem;
}

.title {
  font-size: 2.4rem;
  font-weight: 700;
  margin-bottom: 8px;
}
.subtitle {
  font-size: 1.6rem;
  font-weight: 700;
  color: $color-label;
  @media (min-width: $width-mobile) {
    font-size: 1.8rem;
  }
}

.actions {
  display: none;
  @media (min-width: $width-mobile) {
    display: block;
  }
}
