@import 'theme/Color';

.timeInput {
  display: flex;
  margin: 0 0 12px;
}

.input {
  -webkit-appearance: none;
  box-shadow: none;
  box-sizing: inherit;
  border: 1px solid $color-border;
  font-variant-numeric: tabular-nums;
  border-radius: 4px;
  margin-right: 4px;
  padding: 6px 16px;
  width: 120px;
  font-size: 1.8rem;
  text-align: right;
  line-height: 32px;
  &:focus {
    outline: 0;
    border: 1px solid $color-border-heavy;
  }
}
